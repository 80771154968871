import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "./HeroImageSlider.scss";
import { useTranslation } from "react-i18next";
import { getNavigationItems } from "../navigation/NavItems";
const HeroImageSlider = () => {

  const [bgIndex, setBgIndex] = useState(0);
  const { t, i18n } = useTranslation();

  const importImages = (r: __WebpackModuleApi.RequireContext): string[] => {
    const images: string[] = [];
    r.keys().forEach((item: string, index: number) => {
      images.push(r(item));
    });
    return images;
  };

  const backgroundImages: string[] = importImages(require.context('../../../public/backgroundImages', false, /\.(png|jpe?g|svg)$/));


  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((bgIndex + 1) % backgroundImages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [bgIndex, backgroundImages.length]);

  return (
    <div className="hero-container">
      <div className="hero-section" style={{ backgroundImage: `url(${backgroundImages[bgIndex]})` }}>
        <h1>{t("HERO_TITLE")}</h1>
        <p>{t("HERO_HEADER")}</p>

        <Link to={getNavigationItems(i18n.language)[0].hashLink} spy={true} offset={-100} duration={1000} smooth={true}>
          <i className="fa fa-angle-double-down"></i>
        </Link>
      </div>
    </div>
  );
};

export default HeroImageSlider;