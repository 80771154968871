interface INavItem {
    title: string;
    hashLink: string;
}

export const getNavigationItems = (language: string) => {
    if (language === 'pl') {
        return PLNavigationItems;
    } else {
        return ENNavigationItems;
    }
}

const PLNavigationItems: INavItem[] = [
    {
        title: "O nas",
        hashLink: "about"
    },
    {
        title: "Oferta",
        hashLink: "offer"
    },
    {
        title: "Produkty",
        hashLink: "products"
    },
    {
        title: "Kontakt",
        hashLink: "contact"
    }
]

const ENNavigationItems: INavItem[] = [
    {
        title: "About",
        hashLink: "about"
    },
    {
        title: "Offer",
        hashLink: "offer"
    },
    {
        title: "Products",
        hashLink: "products"
    },
    {
        title: "Contact",
        hashLink: "contact"
    }
]