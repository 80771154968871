import RoundedIcon from "../helpers/RoundedIcon";

interface GridElementProps {
    headerText: string;
    descriptionText: string;
    iconUrl: string;
}
export default function GridElement(props: GridElementProps) {
    return (
        <div className='janus-biz-single-grid-element'>
            <RoundedIcon iconUrl={props.iconUrl} iconSize='20px' circleRadius='64px' />
            <h3>{props.headerText}</h3>
            <p>{props.descriptionText}</p>
        </div>
    )
}