import ReactGA from 'react-ga4';

const TRACKING_ID = `${process.env.REACT_APP_GOOGLE_ANALYTICS}`;

export default class AnalyticsHelper {

    public static initialize() {
        ReactGA.initialize(TRACKING_ID);
    }

    public static monitorUrls() {
        ReactGA.send("pageview")
    }

    public static useAnalyticsEventTracker(category: string) {
        const eventTracker = (action: string, label: string) => {
            ReactGA.event({ category, action, label });
        }
        return eventTracker;
    }

}