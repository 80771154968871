import './App.scss';
import Navbar from './components/navigation/Navbar';

import { Helmet, HelmetProvider } from "react-helmet-async";
import CONSTANTS from './Constants';
import Home from './pages/Home';
import About from './pages/About';
import Divider from './components/helpers/Divider';
import "./pages/PagesStyles.scss";
import { useTranslation } from 'react-i18next';
import Offer from './pages/Offer';
import Products from './pages/Products';
import Contact from './pages/Contact';
import Footer from './pages/Footer';

function App() {

  const { t, i18n } = useTranslation();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{t('SITE_TITLE')}</title>
          <meta
            name="description"
            content={CONSTANTS.SITE_DESCRIPTION}
          />
        </Helmet>
        <Navbar />

        <div className='container'>
          <Home />
          <div className='page-content'>
            <Divider />
            <About />
            <Offer />
            <Products />
            <Contact />
            <Footer />
          </div>
        </div>
      </HelmetProvider>
    </>

  );
}

export default App;
