import { Element } from 'react-scroll'
import { Trans, useTranslation } from 'react-i18next';
import SkillsGrid from '../components/skills/SkillsGrid';

export default function Offer() {
    const { t } = useTranslation();

    return (
        <Element name="offer" className='janus-biz-section'>
            <h1>{t('SECTION_OFFER.header')}</h1>
            <SkillsGrid translation={t}/>
        </Element>
    )
}