import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationPL from "../../locales/pl-pl.json";
import translationEN from "../../locales/en-us.json";

const fallbackLng = ['en'];
const availableLanguages = ['pl', 'en'];

const resources = {
  pl: {
    translation: translationPL
  },
  en: {
    translation: translationEN
  }
};

const getLanguage = (): string => window.localStorage.i18nextLng || 'pl'

export default class MultiLanguage {
  public static init() {
    i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        resources,
        fallbackLng: fallbackLng,
        supportedLngs: availableLanguages,
        lng: getLanguage()
      })
    return i18n;
  }
}
