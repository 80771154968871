import '../App.css';
import HeroImageSlider from '../components/imageSlider/HeroImageSlider';

const Home = () =>  {
  return (
    <>
      <HeroImageSlider/>
    </>
  );
}

export default Home;