import { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";

export default function LanguageComponent() {
    const { i18n } = useTranslation();
    const [selectedCountry, setCountry] = useState(getFlagByLanguage(i18n.language))

    function onLanguageChange(language: any) {
        i18n.changeLanguage(language);
    }

    function getFlagByLanguage(languageName: string) {
        if (languageName === 'pl')
            return 'PL';
        else if (languageName === 'en')
            return 'GB'
        return 'GB'
    }

    function getLanguageByFlag(flag: string) {
        if (flag === 'PL')
            return 'pl';
        else if (flag === 'GB')
            return 'en'
        return 'en'
    }

    useEffect(() => {
        onLanguageChange(getLanguageByFlag(selectedCountry))
    }, [selectedCountry])

    return (
        <div className='janus-biz-language-chooser'>
            <ReactFlagsSelect
                countries={["PL", "GB"]}
                showOptionLabel={false}
                showSelectedLabel={false}
                onSelect={flag => setCountry(flag)}
                selected={selectedCountry}
                className="janus-biz-flags-chooser"
            />
        </div>
    )
}