import { Element } from 'react-scroll'
import AnalyticsHelper from '../components/helpers/AnalyticsHelper'
import { Trans, useTranslation } from 'react-i18next';

export default function Products() {
    const { t } = useTranslation();

    const getProductList = () => {
        return [
            {
                title: 'SECTION_PRODUCTS.products.redomax.title',
                description: 'SECTION_PRODUCTS.products.redomax.description',
                image: "/products/redomax.jpg",
                link: null
            },
            {
                title: 'SECTION_PRODUCTS.products.feedoil.title',
                description: 'SECTION_PRODUCTS.products.feedoil.description',
                image: "/products/feedoil.png",
                link: "https://feedoil.pl/"
            }
        ]
    }

    return (
        <Element name="products" className='janus-biz-section'>
            <h1>{t('SECTION_PRODUCTS.header')}</h1>
            <div className='janus-biz-products'>
                {
                    getProductList().map(product =>
                        <div className='product'>
                            {product.link ?
                                <a href={product.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <h2 className='header'>{t(product.title)}</h2>
                                </a> :
                                <h2 className='header'>{t(product.title)}</h2>
                            }

                            {product.link ?
                                <a href={product.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <img alt={product.title} src={product.image} className='photo' />
                                </a> :
                                <img alt={product.title} src={product.image} className='photo' />
                            }
                            <p className='description'>
                                <Trans i18nKey={product.description}>
                                    {[
                                        <span className='janus-biz-bold'></span>
                                    ]}
                                </Trans>
                            </p>
                        </div>
                    )
                }
            </div>
        </Element>
    )
}