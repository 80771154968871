import { Link } from 'react-scroll'
import { getNavigationItems } from './NavItems';

interface NavLinksProps {
    className: string;
    currentLanguage: string;
    isHamburgerOpen: boolean;
    setHamburgerOpenHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function NavLinks({ className, isHamburgerOpen, setHamburgerOpenHook, currentLanguage }: NavLinksProps) {
    return (
        <div className={className}>
            <ul>
                {getNavigationItems(currentLanguage).map((item, index) => {
                    return (
                        <>
                            <li>
                                <Link
                                    to={item.hashLink}
                                    activeClass="active"
                                    spy={true}
                                    offset={-100}
                                    duration={1000}
                                    smooth={true}
                                    key={index}
                                    onClick={() => {
                                        if (isHamburgerOpen) {
                                            setHamburgerOpenHook(false);
                                        }
                                    }
                                    }
                                >
                                    {item.title}
                                </Link>
                            </li>
                        </>
                    )
                })}
            </ul>
        </div>
    )
}