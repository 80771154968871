import * as Scroll from 'react-scroll';


interface IHomeNav {
    scroll: typeof Scroll.animateScroll;
    title: string;
    logoAltText: string;
    logo: string;
}

export default function HomeNav(props: IHomeNav) {
    return (
        <div className='home'>
            <img className='logo' alt={props.logoAltText} src={props.logo} onClick={() => props.scroll.scrollToTop()}></img>
            <a className="site-title" onClick={() => props.scroll.scrollToTop()}>
                {props.title}
            </a>
        </div>
    )
}