import { Element } from 'react-scroll';
import "./PagesStyles.scss";
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';

export default function About() {
    const { t, i18n } = useTranslation();
    return (
        <Element name="about" className='janus-biz-section'>
            <h1>{t('SECTION_COMPANY.about')}</h1>
            <p>
                <Trans i18nKey='SECTION_COMPANY.aboutText1'>
                    {[
                        <span className='janus-biz-bold'></span>
                    ]}
                </Trans>
            </p>
            <p>
                <Trans i18nKey='SECTION_COMPANY.aboutText2'>
                    {[
                        <span className='janus-biz-bold'></span>
                    ]}
                </Trans>
            </p>
            <p>
                <Trans i18nKey='SECTION_COMPANY.aboutText3'>
                    {[
                        <span className='janus-biz-bold'></span>
                    ]}
                </Trans>
            </p>
        </Element>
    )
}