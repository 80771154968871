const CONSTANTS = {
    SITE_TITLE: 'Janus.biz - Producent oleju i tłuszczy paszowych',
    SITE_DESCRIPTION: 'Koncentraty tłuszczowe, tłuszcze paszowe, oleje i tłuszcze roślinne, witaminy, dodatki paszowe',
    HERO_TITLE: 'janus-biz',
    HERO_HEADER: 'Animal Nutrition',
    HERO_DESCRIPTION: "KONCENTRATY TŁUSZCZOWE, TŁUSZCZE PASZOWE, OLEJE I TŁUSZCZE ROŚLINNE, WITAMINY, DODATKI PASZOWE",
    COOKIE: {
        consent_text: "Niniejsza witryna korzysta z plików cookies, na których opierają się mechanizmy Google Analytics. Dalsze korzystanie ze strony oznacza zgodę na ich użycie. Więcej informacji na temat plików cookie stosowanych przez Google Analytics znajduje się pod adresami:",
        accept: "Akceptuj",
        decline: "Odrzuć"
    },
    SECTION_QUALITY: {
        veterinary_number: "PL3214104 p",
        qs_number: "4953113156068",
        gmp_number: "GMP009055"
    },
    SECTION_CONTACT: {
        map_coordinates: {
            x: 53.47247412824922,
            y: 15.33416633863069
        }
    }
}

export default CONSTANTS;