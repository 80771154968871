import GridElement from "./GridElement";

interface ISkillsGrid {
    translation: any;
}

export default function SkillsGrid({ translation }: ISkillsGrid) {
    return (
        <div className='skills-grid'>
            <GridElement
                headerText={translation('SECTION_OFFER.skills.materials.title')}
                descriptionText={translation('SECTION_OFFER.skills.materials.description')}
                iconUrl="/icons/icon8.png"
            />
            <GridElement
                headerText={translation('SECTION_OFFER.skills.methods_organic.title')}
                descriptionText={translation('SECTION_OFFER.skills.methods_organic.description')}
                iconUrl="/icons/icon3.png"
            />
            <GridElement
                headerText={translation('SECTION_OFFER.skills.methods_synthesis.title')}
                descriptionText={translation('SECTION_OFFER.skills.methods_synthesis.description')}
                iconUrl="/icons/icon6.png"
            />
            <GridElement
                headerText={translation('SECTION_OFFER.skills.constructions.title')}
                descriptionText={translation('SECTION_OFFER.skills.constructions.description')}
                iconUrl="/icons/icon2.png"
            />
            <GridElement
                headerText={translation('SECTION_OFFER.skills.design_ionic_liquids.title')}
                descriptionText={translation('SECTION_OFFER.skills.design_ionic_liquids.description')}
                iconUrl="/icons/icon5.png"
            />
            <GridElement
                headerText={translation('SECTION_OFFER.skills.research_ionic_liquids.title')}
                descriptionText={translation('SECTION_OFFER.skills.research_ionic_liquids.description')}
                iconUrl="/icons/icon7.png"
            />
        </div>
    )
}