import * as Scroll from 'react-scroll';
import { useTranslation } from 'react-i18next';
import React from 'react';

export default function Footer() {
    const { t } = useTranslation();
    let scroll = Scroll.animateScroll;
    return (
        <div className="janus-biz-section janus-biz-footer">
            <p><a className='janus-biz-link home' onClick={() => scroll.scrollToTop()}>Janus.biz Sp. z o.o.</a>{new Date().getFullYear()}</p>
            <p>{t('SECTION_FOOTER.implementation')} <a className='janus-biz-link' href="mailto:piotr.janus@janus.biz">Piotr Janus</a></p>
            <p style={{ marginTop: '5px' }}>
                <a href="https://profiles.eco/janus?ref=tm" rel="noopener">
                    <img decoding="async" loading="lazy" className="eco-trustmark alignnone" src="https://trust.profiles.eco/janus/eco-button.svg?color=%230C7FE4" alt=".eco profile for janus.eco" width="36" />
                </a>
            </p>
        </div>
    )
}