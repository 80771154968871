interface RoundedIconProps {
    iconSize: string;
    circleRadius: string;
    iconUrl: string;
}

export default function RoundedIcon(props: RoundedIconProps) {
    return (
        <div className='janus-biz-rounded-icon' style={{
            height: props.circleRadius,
            width: props.circleRadius,
            fontSize: props.iconSize,
        }}>
            <img alt="Offer icon" src={props.iconUrl} style={{
                width: props.circleRadius,
            }} />
        </div>
    )
}